import * as React from "react"
import { t } from "ttag"
import { CommentEditorContainer } from "features/Comments"
import { Comment, CommentAttribute } from "models/Comment"
import { USER_ATTRIBUTE_VALUE } from "models/UserAttributeValue"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "features/Surveys/ScheduledSurveyResults/AttributableFeedback/AttributableFeedback.styles"
import { CommentObjectType } from "models/generated"

/**
 * Components to create a feedback associated to an attribute
 * e.g. It is used to create a feedback per `departement` attribute on the `question` and `scheduled_survey` entities
 * @param props
 * @constructor
 */
export const AttributableFeedback: React.FC<ScheduledSurveyLocalFeedbackProps> = (props) => {
  return (
    <Styles.Container className={props.className}>
      <Styles.Explanation
        text={t`Once published, your feedback will be visible in the results interface of all targeted users.`}
      />
      {props.attributeValues.map(attributeValue => {
        if (attributeValue.usersCount && attributeValue.usersCount > 0) {
          const attributeComment = commentForAttributeId(attributeValue.id)
          return (
            <CommentEditorContainer
              label={`${attributeValue.attributeLabel} ${attributeValue.label}`}
              key={attributeValue.id}
              isAllowedToWrite={props.isAllowedToWrite?.()}
              isAllowedToPublish={props.isAllowedToPublish?.()}
              comment={attributeComment}
              commentMetadata={{
                attributeValueId: attributeValue.id,
                objectId: props.objectId,
                objectType: props.type,
              }}
              isStatusDisplayed
              isImmediatePublishing={props.areResultsAlreadyPublished}
              onSaved={() => props.onSaved?.()}
              onDeleted={props.onDeleted}
            />
          )
        }
        return undefined
      })}
    </Styles.Container>
  )

  /**
   * Get the comment associated with the given attribute value ID
   * @param attributeValueId ID of the attribute value for which to look
   */
  function commentForAttributeId(attributeValueId: number) {
    return props.comments?.find(comment => comment.attribute?.valueId === attributeValueId)
  }
}

interface ScheduledSurveyLocalFeedbackProps extends BaseProps {
  /** ID of the object to address the feedback to */
  objectId: number,

  /** Type of the feedback */
  type: CommentObjectType,

  /** Attribute values for which to write a feedback */
  attributeValues: USER_ATTRIBUTE_VALUE[],

  /** Scoped comments */
  comments?: Array<Comment & CommentAttribute>,

  /** Date of publishing if the comment is already published */
  publishingDate?: string,

  /** Whether the results are already accessible to recipients */
  areResultsAlreadyPublished?: boolean,

  /** Optional callback when the comment is saved (created or updated) */
  onSaved?: () => void,

  /** Optional callback when the comment is deleted */
  onDeleted?: () => void,

  /** Callback called to check if the user is allowed to publish the feedback */
  isAllowedToWrite?: () => boolean,

  /** Callback called to check if the user is allowed to publish the feedback */
  isAllowedToPublish?: () => boolean,
}
