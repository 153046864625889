import * as React from "react"
import { ResponsiveBar } from "@nivo/bar"
import {
  Card,
  CenteredLoader,
  Skeletonable,
  Title,
  nivoMyQvtTheme,
  withSkeleton,
} from "@humanpredictiveintelligence/myqvt-library"
import AutoSizer from "react-virtualized-auto-sizer"
import { msgid, ngettext, t } from "ttag"

import * as Styles from "features/Led/StatisticCharts/BreakdownDiscussionsChart/BreakdownDiscussionsChart.styles"

const BreakdownDiscussionsChartSkeleton: React.FC<BreakdownDiscussionsChartProps> = () => (
  <AutoSizer>
    {({ width, height }: { height: number, width: number }) => (
      <Styles.Container $width={width} $height={height}>
        <Title level="metricCard">
          {t`Breakdown of discussions`}
        </Title>
        <CenteredLoader isTransparent/>
      </Styles.Container>
    )}
  </AutoSizer>
)

export const BreakdownDiscussionsChartComponent: React.FC<Skeletonable<BreakdownDiscussionsChartProps>> = (props) => (
  <AutoSizer>
    {({ width, height }: { height: number, width: number }) => (
      <Styles.Container $width={width} $height={height}>
        <Title level="metricCard">
          {t`Breakdown of discussions`}
        </Title>
        <ResponsiveBar
          colors={[ "#236fff", "#ffc107" ]}
          borderColor={{ theme: "background" }}
          data={props.chartData}
          keys={[ "recipientsCount" ]}
          indexBy="reportsCountLabel"
          groupMode="grouped"
          theme={nivoMyQvtTheme}
          tooltip={({ data }) => {
            const numberOfRecipients = data.recipientsCount
            const reportsLabel = data.reportsCountLabel.toLowerCase()

            return (
              <Card shadow="button" isReducedPadding>
                <span>
                  {ngettext(
                    msgid`${numberOfRecipients} recipient made ${reportsLabel}`,
                    `${numberOfRecipients} recipients made ${reportsLabel}`,
                    numberOfRecipients,
                  )}
                </span><br/>
              </Card>
            )
          }}
          isInteractive
          layout="vertical"
          margin={{ bottom: 60, left: 30, right: 0, top: 20 }}
          padding={1.15 - (props.chartData.length / 25)}
          innerPadding={4}
          valueScale={{ type: "linear" }}
          indexScale={{ round: true, type: "band" }}
          enableGridX={false}
          animate
          enableGridY
          borderRadius={5}
          enableLabel
          labelTextColor={{
            from: "color",
            modifiers: [
              [
                "brighter",
                10,
              ],
            ],
          }}
          axisLeft={{
            format: (e) => Math.floor(e) === e && e,
          }}
        />
      </Styles.Container>
    )}
  </AutoSizer>
)

export const BreakdownDiscussionsChart = withSkeleton(
  BreakdownDiscussionsChartComponent,
  BreakdownDiscussionsChartSkeleton,
)

type BreakdownDiscussionsDatum = {
  recipientsCount: number,
  reportsCountLabel: string,
}

export interface BreakdownDiscussionsChartProps {
  chartData: BreakdownDiscussionsDatum[],
}
