import React, { useState } from "react"

import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"
import { t } from "ttag"

const MFA_RETRY_TIMEOUT_IN_SECONDS = 30

export const ResendMfaCodeButton: React.FC<ResendMfaCodeButtonProps> = (props) => {
  const [ counter, setCounter ] = useState<number>(MFA_RETRY_TIMEOUT_IN_SECONDS)

  const isCounterOngoing = counter > 0

  React.useEffect(() => {
    isCounterOngoing && setTimeout(() => setCounter(counter - 1), 1000)
  }, [ counter, isCounterOngoing ])

  return (
    <>
      <PrimaryButton
        onClick={onRetry}
        disabled={isCounterOngoing}
      >
        {t`Send a new code` + `${displayCounter()}`}
      </PrimaryButton>
    </>
  )

  function displayCounter(): string {
    return isCounterOngoing ? ` (${counter.toString()})` : ""
  }

  function onRetry() {
    props.onRetry()
    setCounter(MFA_RETRY_TIMEOUT_IN_SECONDS)
  }
}

interface ResendMfaCodeButtonProps {
  onRetry: () => void,
}
