/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestAuthenticationMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
  customerCode?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type RequestAuthenticationMutation = { authenticate: { token: string, mfa: { method: Types.MfaMethod, status: Types.MfaStatus }, user?: { backupEmail?: string | undefined, customerCode: string, id: number, type: Types.UserType } | undefined } };


export const RequestAuthenticationDocument = gql`
    mutation RequestAuthentication($email: String!, $password: String!, $customerCode: String) {
  authenticate(email: $email, password: $password, customerCode: $customerCode) {
    token
    mfa {
      method
      status
    }
    user {
      backupEmail
      customerCode
      id
      type
    }
  }
}
    `;

/**
 * __useRequestAuthenticationMutation__
 *
 * To run a mutation, you first call `useRequestAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAuthenticationMutation, { data, loading, error }] = useRequestAuthenticationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      customerCode: // value for 'customerCode'
 *   },
 * });
 */
export function useRequestAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<RequestAuthenticationMutation, RequestAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestAuthenticationMutation, RequestAuthenticationMutationVariables>(RequestAuthenticationDocument, options);
      }
export type RequestAuthenticationMutationHookResult = ReturnType<typeof useRequestAuthenticationMutation>;
export type RequestAuthenticationMutationResult = Apollo.MutationResult<RequestAuthenticationMutation>;
export type RequestAuthenticationMutationOptions = Apollo.BaseMutationOptions<RequestAuthenticationMutation, RequestAuthenticationMutationVariables>;