/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyUserFragmentFragmentDoc } from '../../../models/generated/SkinnyUserFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMeMutationVariables = Types.Exact<{
  userLanguage?: Types.InputMaybe<Types.Scalars['String']>;
  backupEmail?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateMeMutation = { me: { id: number, email?: string | undefined, backupEmail?: string | undefined, backupEmailValidateUntil?: string | undefined, timezone: string, firstname: string, lastname: string, displayName: string, phone?: string | undefined, isAdmin: boolean, language: { code: string, localizedLabel: string, translatedLabel: string }, attributes: Array<{ name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> }> } };


export const UpdateMeDocument = gql`
    mutation UpdateMe($userLanguage: String, $backupEmail: String) {
  me(language: $userLanguage, backupEmail: $backupEmail) {
    ...SkinnyUserFragment
  }
}
    ${SkinnyUserFragmentFragmentDoc}`;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      userLanguage: // value for 'userLanguage'
 *      backupEmail: // value for 'backupEmail'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;