import { ServerParseError, useApolloClient } from "@apollo/client"
import { ApolloError } from "@apollo/client/errors"
import * as React from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useRollbar } from "@rollbar/react"

import { useSession } from "features/Session"
import { LoginPageRouteState } from "features/Login"
import { APPLICATION_URL } from "features/Navigation"
import { SplashScreen } from "features/SplashScreen"
import { STATIC_SESSION } from "utilities/StaticSession"
import { MfaMethod, MfaStatus, UserPermissionCode, UserType } from "models/generated"
import { getUserMfaState, validateUserMfaState } from "features/Login/MfaCodeForm/MfaValidation"

import { SessionDocument, SessionQuery } from "graphql/queries/generated/Session"
import { SessionUsersDocument, SessionUsersQuery } from "graphql/queries/generated/SessionUsers"
import { LoginDocument, LoginMutation, LoginMutationVariables } from "graphql/mutations/generated/Login"
import {
  RequestAuthenticationDocument,
  RequestAuthenticationMutation,
  RequestAuthenticationMutationVariables,
} from "graphql/queries/generated/RequestAuthentication"
import { useGetAvailableAppsLazyQuery } from "graphql/queries/generated/GetAvailableApps"
import { useCheckInMutation } from "graphql/mutations/generated/CheckIn"

interface AuthenticationApi {
  authenticate: (
    email: string,
    password: string,
    skipValidation?: boolean,
    authStepToken?: string,
    mfaToken?: string,
    customerCode?: string,
  ) => Promise<boolean>,
  requestAuthentication: (email: string, password: string) => Promise<AuthenticationStatus>,
  forceAuthentication: () => void,
  logout: () => void,
  getUserSession: () => Promise<void>,
}

type AuthenticationStatus = RequestAuthenticationMutation["authenticate"]

const defaultAuthenticationApi: AuthenticationApi = {
  authenticate: () => new Promise<boolean>(resolve => resolve(false)),
  forceAuthentication: () => {/* empty */},
  getUserSession: () => new Promise<void>(resolve => resolve()),
  logout: () => { /* empty */ },
  requestAuthentication: () => new Promise<AuthenticationStatus>(resolve => resolve({
    mfa: {
      method: MfaMethod.None,
      status: MfaStatus.Disabled,
    },
    token: "",
    user: {
      backupEmail: "",
      customerCode: "",
      id: 0,
      type: UserType.User,
    },
  })),
}

const authenticationContext = React.createContext<AuthenticationApi>(defaultAuthenticationApi)

// Create a wrapper for the provider so we can manage context state
const AuthenticationProvider = (props: { children: React.ReactChild }) => {
  const session = useSession()
  const apolloClient = useApolloClient()
  const location = useLocation<LoginPageRouteState>()
  const history = useHistory()
  const rollbar = useRollbar()

  const searchParams = new URLSearchParams(location.search)
  const tokenFromUrl = searchParams.get("t") || undefined
  const [ isTokenRecoveryDone, setTokenRecoveryDone ] = React.useState(false)
  const memorizedTryRecoveryingAuthenticationFromToken = React.useCallback(
    tryRecoveringAuthenticationFromToken,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ tokenFromUrl ],
  )

  const [ getAvailableApps ] = useGetAvailableAppsLazyQuery()
  const [ checkIn ] = useCheckInMutation()

  React.useEffect(() => {
    memorizedTryRecoveryingAuthenticationFromToken()
  }, [ memorizedTryRecoveryingAuthenticationFromToken ])

  if (tokenFromUrl) {
    STATIC_SESSION.authenticationToken = tokenFromUrl
    searchParams.delete("t")
    history.replace(`${location.pathname}?${searchParams.toString()}`)

    return null
  }
  else {
    return (
      <authenticationContext.Provider
        value={{
          authenticate,
          forceAuthentication: redirectUserToHubOrHome,
          getUserSession,
          logout,
          requestAuthentication,
        }}
      >
        {isTokenRecoveryDone ? props.children : <SplashScreen/>}
      </authenticationContext.Provider>
    )
  }

  /**
   * Try recovering the authentication state if there is a token available.
   * This will update the context state with authentication state if recovered.
   */
  function tryRecoveringAuthenticationFromToken(): void {
    if (!isTokenRecoveryDone) {
      new Promise<void>(async (resolve) => {
        if (!STATIC_SESSION.authenticationToken) {
          resolve()
          return
        }
        try {
          await getUserSession()

          session.dispatch(session.actions.setAuthentication({
            hasFailed: false,
            isAuthenticated: true,
            lastFailedAttempt: undefined,
          }))
        }
        catch (error) {
          if (error instanceof ApolloError && error.networkError && error.networkError.name === "ServerParseError") {
            const serverError = error.networkError as ServerParseError

            if (serverError.statusCode === 401) {
              logout()
            }
          }
        }
        finally {
          resolve()
        }
      }).then(() => { setTokenRecoveryDone(true) })
    }
  }

  async function requestAuthentication(email: string, password: string): Promise<AuthenticationStatus> {
    return new Promise(async (resolve, reject) => {
      try {
        const requestResponse = await apolloClient.mutate<
          RequestAuthenticationMutation,
          RequestAuthenticationMutationVariables
        >({
          mutation: RequestAuthenticationDocument,
          variables: {
            customerCode: STATIC_SESSION.customer,
            email,
            password,
          },
        })

        if (requestResponse.data?.authenticate) {
          resolve(requestResponse.data.authenticate)
        }
        else {
          session.dispatch(session.actions.setAuthentication({
            hasFailed: true,
            isAuthenticated: false,
            lastFailedAttempt: (new Date().toString()),
          }))

          reject()
        }
      }
      catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors) {
          session.dispatch(session.actions.setAuthentication({
            hasFailed: true,
            isAuthenticated: false,
            lastFailedAttempt: (new Date().toString()),
            reason: error.graphQLErrors?.[0]?.message,
          }))
        }
        reject()
      }
    })
  }

  // These methods update the context state
  // They are included in the context as an API
  // ---
  async function authenticate(
    email: string,
    password: string,
    skipValidation: boolean = false,
    authStepToken?: string,
    mfaToken?: string,
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const loginResponse = await apolloClient.mutate<LoginMutation, LoginMutationVariables>({
          context: {
            shouldUseAuthSchema: true,
          },
          mutation: LoginDocument,
          variables: {
            authStepToken: authStepToken,
            customerCode: STATIC_SESSION.customer,
            email: email,
            mfaToken: mfaToken,
            password: password,
          },
        })

        const authenticationData = loginResponse.data?.authenticate

        if (!authenticationData?.user) {
          failAuthentication()
          reject()
          return
        }

        const mfaState = getUserMfaState(authenticationData)
        const isMfaValid = validateUserMfaState(mfaState)

        if (!isMfaValid) {
          failAuthentication()
          reject(mfaState)
          return
        }

        STATIC_SESSION.authenticationToken = authenticationData.token
        STATIC_SESSION.customer = authenticationData.user.customerCode

        await getUserSession()
        await checkIn()

        if (!skipValidation && mfaState.status === MfaStatus.PassedWithoutBackupValidation) {
          completeAuthentication()
          reject(mfaState)
        }
        else {
          completeAuthentication()
          await redirectUserToHubOrHome()
          resolve(true)
        }
      }
      catch (error) {
        const reason  = error instanceof ApolloError ? error.graphQLErrors?.[0]?.message : error.message
        failAuthentication(reason)

        reject()
      }
    })
  }

  async function getUserSession(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const sessionQuery = await apolloClient.query<SessionQuery>({
          context: {
            shouldNotShowErrorIfUnauthorized: true,
          },
          query: SessionDocument,
        })

        if (sessionQuery.data) {
          session.dispatch(session.actions.setUser(sessionQuery.data.me))
          session.dispatch(session.actions.setCustomer(sessionQuery.data.me.customer))
          session.dispatch(session.actions.setLanguages(sessionQuery.data.languages))

          rollbar.configure({
            payload: {
              person: {
                id: sessionQuery.data.me.id,
              },
            },
          })

          const recipientReadPermission = sessionQuery.data.me.permissions.find(
            ({ permission }) => permission.code === UserPermissionCode.RecipientsRead,
          )

          if (recipientReadPermission?.isGranted) {
            const sessionUsersQuery = await apolloClient.query<SessionUsersQuery>({
              context: {
                shouldNotShowErrorIfUnauthorized: true,
              },
              query: SessionUsersDocument,
            })
            session.dispatch(session.actions.setUsers(sessionUsersQuery.data.me.users))
          }

          resolve()
        }
      }
      catch {
        logout()
        reject()
      }
    })
  }

  async function redirectUserToHubOrHome(): Promise<void> {
    const { data } = await getAvailableApps()

    if (data?.getAvailableApps && data.getAvailableApps.length > 1) {
      return history.push(APPLICATION_URL.appHub())
    }

    history.push(APPLICATION_URL.base())
  }

  function logout(): void {
    // Flush the token from the session to avoid auto-login on next page refresh/navigation
    STATIC_SESSION.authenticationToken = undefined

    session.dispatch(session.actions.unauthenticate())
    history.push("/login")

    rollbar.configure({
      payload: {
        person: undefined,
      },
    })
  }

  function completeAuthentication(): void {
    session.dispatch(session.actions.setAuthentication({
      hasFailed: false,
      isAuthenticated: true,
      lastFailedAttempt: undefined,
    }))
  }

  function failAuthentication(reason?: string): void {
    session.dispatch(session.actions.setAuthentication({
      hasFailed: true,
      isAuthenticated: false,
      lastFailedAttempt: new Date().toString(),
      reason,
    }))
  }
}

export {
  authenticationContext as AuthenticationContext,
  AuthenticationProvider,
}
