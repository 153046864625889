import React from "react"
import { t } from "ttag"

import * as Styles from "./BackupEmail.styles"

import { useSetupBackupEmailMutation } from "graphql/mutations/generated/SetupBackupEmail"

export const ExpiredBackupEmail: React.FC<ExpiredBackupEmailProps> = (props) => {
  const [ wasBackupEmailUpdated, setWasBackupEmailUpdated ] = React.useState(false)
  const [ updateBackupEmail, { loading } ] = useSetupBackupEmailMutation()

  return (
    <Styles.Container>
      <div>
        <Styles.Title>{t`Validate your backup email`}</Styles.Title>
        <Styles.InstructionParagraph>
          {t`A confirmation email has been sent to the address`} {props.backupEmail}.
        </Styles.InstructionParagraph>
        <Styles.InstructionParagraph>
          {t` Please check your mailbox and click on the confirmation link to validate your email address.`}
        </Styles.InstructionParagraph>
        <Styles.InformationMessageBox variant="warning" text={t`
          The 30-day validation period has expired. Your account is no longer accessible until your backup email 
          has been validated.
        `}
        />
      </div>

      <div>
        <Styles.SubmitButton
          onClick={() => wasBackupEmailUpdated
            ? props.onBackupEmailValidationSent()
            : sendNewBackupEmailValidationAndRestart(props.backupEmail, props.actionToken)
          }
          isLoading={loading}
        >
          {wasBackupEmailUpdated ? t`Retry a connection` : t`Resend a validation email`}
        </Styles.SubmitButton>
        <Styles.ForgotPasswordText onClick={props.onChangeBackupEmailRequested}>
          {t`Change backup email`}
        </Styles.ForgotPasswordText>
      </div>
    </Styles.Container>
  )

  async function sendNewBackupEmailValidationAndRestart(email: string, actionToken: string) {
    try {
      await updateBackupEmail({
        variables: {
          actionToken,
          email,
        },
      })

      setWasBackupEmailUpdated(true)
    }
    catch (error) {
      setWasBackupEmailUpdated(false)
    }
  }
}

interface ExpiredBackupEmailProps {
  backupEmail: string,
  actionToken: string,
  onChangeBackupEmailRequested: () => void,
  onBackupEmailValidationSent: () => void,
}
