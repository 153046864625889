/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PsyRecipientSumByPsyReportCountsQueryVariables = Types.Exact<{
  psyDispositifNanoId: Types.Scalars['String'];
  dateInterval?: Types.InputMaybe<Types.DateIntervalInput>;
}>;


export type PsyRecipientSumByPsyReportCountsQuery = { getPsyReportStatistics?: { psyRecipientSumByPsyReportCounts: Array<{ psyRecipientsCount: number, psyReportsCountLabel: string }> } | undefined };


export const PsyRecipientSumByPsyReportCountsDocument = gql`
    query PsyRecipientSumByPsyReportCounts($psyDispositifNanoId: String!, $dateInterval: DateIntervalInput) {
  getPsyReportStatistics(psyDispositifNanoId: $psyDispositifNanoId, dateInterval: $dateInterval) {
    psyRecipientSumByPsyReportCounts {
      psyRecipientsCount
      psyReportsCountLabel
    }
  }
}
    `;

/**
 * __usePsyRecipientSumByPsyReportCountsQuery__
 *
 * To run a query within a React component, call `usePsyRecipientSumByPsyReportCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePsyRecipientSumByPsyReportCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePsyRecipientSumByPsyReportCountsQuery({
 *   variables: {
 *      psyDispositifNanoId: // value for 'psyDispositifNanoId'
 *      dateInterval: // value for 'dateInterval'
 *   },
 * });
 */
export function usePsyRecipientSumByPsyReportCountsQuery(baseOptions: Apollo.QueryHookOptions<PsyRecipientSumByPsyReportCountsQuery, PsyRecipientSumByPsyReportCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PsyRecipientSumByPsyReportCountsQuery, PsyRecipientSumByPsyReportCountsQueryVariables>(PsyRecipientSumByPsyReportCountsDocument, options);
      }
export function usePsyRecipientSumByPsyReportCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PsyRecipientSumByPsyReportCountsQuery, PsyRecipientSumByPsyReportCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PsyRecipientSumByPsyReportCountsQuery, PsyRecipientSumByPsyReportCountsQueryVariables>(PsyRecipientSumByPsyReportCountsDocument, options);
        }
export type PsyRecipientSumByPsyReportCountsQueryHookResult = ReturnType<typeof usePsyRecipientSumByPsyReportCountsQuery>;
export type PsyRecipientSumByPsyReportCountsLazyQueryHookResult = ReturnType<typeof usePsyRecipientSumByPsyReportCountsLazyQuery>;
export type PsyRecipientSumByPsyReportCountsQueryResult = Apollo.QueryResult<PsyRecipientSumByPsyReportCountsQuery, PsyRecipientSumByPsyReportCountsQueryVariables>;