import { MfaStatus } from "models/generated"
import { RequestAuthenticationMutation } from "graphql/queries/generated/RequestAuthentication"

export class UserMfaState {
  constructor(readonly status: string) {}
}

export class BackupValidationPeriodExpiredState extends UserMfaState {
  constructor(status: string, readonly setupBackupEmailActionToken: string, readonly backupEmail: string) {
    super(status)
  }
}

export class PassedWithoutBackupValidationState extends UserMfaState {
  constructor(status: string, readonly setupBackupEmailActionToken: string, readonly backupEmail: string) {
    super(status)
  }
}

export class NoBackupEmailState extends UserMfaState {
  constructor(status: string, readonly setupBackupEmailActionToken: string) {
    super(status)
  }
}

export function getUserMfaState(
  authenticationData: RequestAuthenticationMutation["authenticate"],
): UserMfaState {
  if (!authenticationData.user) {
    throw new Error()
  }

  const status = authenticationData.mfa.status
  const backupEmail = authenticationData.user.backupEmail
  const token = authenticationData.token

  switch (authenticationData.mfa.status) {
    case MfaStatus.PassedWithoutBackupValidation:
      validateBackupEmail(backupEmail)

      return new PassedWithoutBackupValidationState(status, token, backupEmail!)
    case MfaStatus.BackupValidationPeriodExpired:
      validateBackupEmail(backupEmail)

      return new BackupValidationPeriodExpiredState(status, token, backupEmail!)
    case MfaStatus.NoBackupEmail:
      return new NoBackupEmailState(status, token)
    default:
      return new UserMfaState(status)
  }
}

export function validateUserMfaState(mfaUserState: UserMfaState): boolean {
  return mfaUserState.status === MfaStatus.Passed
    || mfaUserState.status === MfaStatus.Disabled
    || mfaUserState.status === MfaStatus.PassedWithoutBackupValidation
}

function validateBackupEmail(backupEmail?: string): backupEmail is string {
  if (!backupEmail) {
    throw new Error("Backup email missing")
  }

  return true
}
