import * as React from "react"

import { ContentWithSpeakupLogo } from "features/Shared/ContentWithSpeakupLogo"
import { AuthenticationContext } from "features/Authentication"
import { BackupEmailWarning, ChangeBackupEmailForm, ExpiredBackupEmail } from "features/BackupEmail"
import { FormStatus } from "utilities/types"
import { LoginForm } from "../LoginForm"
import { LoginStep, UserState } from "../models"
import {
  BackupValidationPeriodExpiredState,
  MfaCodeForm,
  NoBackupEmailState,
  PassedWithoutBackupValidationState,
} from "../MfaCodeForm"
import * as Styles from "./LoginPage.styles"

import { MfaStatus } from "models/generated"

export const LoginPage: React.FC = () => {
  const {
    requestAuthentication,
    authenticate,
    forceAuthentication,
  } = React.useContext(AuthenticationContext)
  const [ loginStep, setLoginStep ] = React.useState(LoginStep.Login)
  const [ userState, setUserState ] = React.useState<UserState>({
    email: "",
    password: "",
  })

  const [ formStatusLogin, setFormStatusLogin ] = React.useState<FormStatus>("idle")
  const [ formStatusMfaCode, setFormStatusMfaCode ] = React.useState<FormStatus>("idle")
  const [ formStatusBackupEmail, setFormStatusBackupEmail ] = React.useState<FormStatus>("idle")

  return (
    <ContentWithSpeakupLogo>
      <Styles.CardContainer>
        {getCurrentStepLoginForm(loginStep)}
      </Styles.CardContainer>
    </ContentWithSpeakupLogo>
  )

  function getCurrentStepLoginForm(loginStep: LoginStep) {
    switch (loginStep) {
      case LoginStep.Login :
        return <LoginForm
          email={process.env.REACT_APP_DEV_LOGIN_USER}
          password={process.env.REACT_APP_DEV_LOGIN_PASSWORD}
          onSubmit={(values) => tryAuthenticating(values)}
          formStatus={formStatusLogin}
        />
      case LoginStep.Mfa :
        return <MfaCodeForm
          userEmail={userState.email}
          onCodeValidated={(code) => authenticateWithMfa(code, userState.skipBackupEmailValidation)}
          onRetryRequested={() => tryAuthenticating(userState)}
          onBack={() => setLoginStep(LoginStep.Login)}
          formStatus={formStatusMfaCode}
        />
      case LoginStep.ChangeBackupEmail :
        return <ChangeBackupEmailForm
          onBackupEmailChanged={() => tryAuthenticating(userState, true)}
          actionToken={userState.setupEmailBackupActionToken ?? ""}
          mode={userState.isBackupEmailMissing ? "setup" : "change"}
          formStatus={formStatusBackupEmail}
        />
      case LoginStep.ConfirmBackupEmail :
        return <BackupEmailWarning
          backupEmail={userState.backupEmail ?? ""}
          onSkip={forceAuthentication}
        />
      case LoginStep.ExpiredBackupEmail :
        return <ExpiredBackupEmail
          backupEmail={userState.backupEmail ?? ""}
          actionToken={userState.setupEmailBackupActionToken ?? ""}
          onBackupEmailValidationSent={() => setLoginStep(LoginStep.Login)}
          onChangeBackupEmailRequested={() => goToChangeBackupEmailStep(
            userState.email,
            userState.password,
            userState.setupEmailBackupActionToken ?? "",
          )}
        />
    }
  }

  function goToChangeBackupEmailStep(email: string, password: string, actionToken: string) {
    setUserState({
      email,
      isBackupEmailMissing: false,
      isBackupEmailValidationExpired: false,
      password,
      setupEmailBackupActionToken: actionToken,
    })
    setLoginStep(LoginStep.ChangeBackupEmail)
  }

  async function tryAuthenticating(values: LoginFormFields, skipBackupEmailValidation = false) {
    try {
      setFormStatusLogin("processing")
      setFormStatusBackupEmail("processing")

      const authenticationStatus = await requestAuthentication(values.email, values.password)

      if (authenticationStatus.mfa.status !== MfaStatus.Disabled) {
        setUserState({
          authStepToken: authenticationStatus.token,
          email: values.email,
          password: values.password,
          skipBackupEmailValidation: skipBackupEmailValidation,
        })
        setLoginStep(LoginStep.Mfa)
      }
      else {
        await authenticate(values.email, values.password)
      }
      setFormStatusLogin("idle")
      setFormStatusBackupEmail("idle")
    }
    catch (error) {
      setFormStatusLogin("failed")
      setFormStatusBackupEmail("failed")
      handleError(error)
    }
  }

  async function authenticateWithMfa(mfaCode: string, skipValidation: boolean = false) {
    try {
      setFormStatusMfaCode("processing")
      const { email, password, authStepToken } = userState

      if (!password || !authStepToken) {
        throw new Error("Invalid credentials")
      }

      await authenticate(
        email,
        password,
        skipValidation,
        authStepToken,
        mfaCode,
      )
      setFormStatusMfaCode("idle")
    } catch (error) {
      setFormStatusMfaCode("failed")
      handleError(error)
    }
  }

  function handleError(error: unknown) {
    if (error instanceof NoBackupEmailState) {
      setUserState((prevState) => ({
        ...prevState,
        isBackupEmailMissing: true,
        isBackupEmailValidationExpired: false,
        setupEmailBackupActionToken: error.setupBackupEmailActionToken,
      }))
      setLoginStep(LoginStep.ChangeBackupEmail)
      return
    }
    if (error instanceof BackupValidationPeriodExpiredState) {
      setUserState((prevState) => ({
        ...prevState,
        backupEmail: error.backupEmail,
        isBackupEmailMissing: false,
        isBackupEmailValidationExpired: true,
        setupEmailBackupActionToken: error.setupBackupEmailActionToken,
      }))
      setLoginStep(LoginStep.ExpiredBackupEmail)
      return
    }
    if (error instanceof PassedWithoutBackupValidationState) {
      setUserState((prevState) => ({
        ...prevState,
        backupEmail: error.backupEmail,
        isBackupEmailValidationExpired: false,
        setupEmailBackupActionToken: error.setupBackupEmailActionToken,
      }))
      setLoginStep(LoginStep.ConfirmBackupEmail)
      return
    }
  }
}

export type LoginFormFields = {
  email: string,
  password: string,
}

export interface LoginPageRouteState {
  /** Redirect route after login */
  redirectUrlAfterLogin: string,
}
