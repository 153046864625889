/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyUserFragmentFragmentDoc } from '../../../models/generated/SkinnyUserFragment';
import { SkinnyUserAttributeValueFragmentFragmentDoc } from '../../../models/generated/SkinnyUserAttributeValueFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScopedUsersQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  email?: Types.InputMaybe<Types.Scalars['String']>;
  filterGroup?: Types.InputMaybe<Types.FilterGroups>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  attributeValuesIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
}>;


export type ScopedUsersQuery = { me: { id: number, timezone: string, users: { count: number, list: Array<{ id: number, email?: string | undefined, backupEmail?: string | undefined, backupEmailValidateUntil?: string | undefined, timezone: string, firstname: string, lastname: string, displayName: string, phone?: string | undefined, isAdmin: boolean, scopes: Array<{ id: number, name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { name: string, value: string, attributeId: number, valueId: number, isMainHierarchyHead: boolean, parent?: { name: string, value: string, attributeId: number, valueId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> } | undefined, children: Array<{ attributeId: number, valueId: number }> } | undefined, children: Array<{ attributeId: number, valueId: number }> }>, language: { code: string, localizedLabel: string, translatedLabel: string }, attributes: Array<{ name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> }> }> } } };


export const ScopedUsersDocument = gql`
    query ScopedUsers($limit: Int!, $skip: Int, $email: String, $filterGroup: FilterGroups, $search: String, $attributeValuesIds: [Int!]) {
  me {
    id
    timezone
    users(limit: $limit, skip: $skip, email: $email, filterGroup: $filterGroup, search: $search, attribute_value_ids: $attributeValuesIds) {
      list {
        ...SkinnyUserFragment
        scopes {
          ...SkinnyUserAttributeValueFragment
          parent {
            ...SkinnyUserAttributeValueFragment
            parent {
              ...SkinnyUserAttributeValueFragment
            }
          }
          id
        }
      }
      count
    }
  }
}
    ${SkinnyUserFragmentFragmentDoc}
${SkinnyUserAttributeValueFragmentFragmentDoc}`;

/**
 * __useScopedUsersQuery__
 *
 * To run a query within a React component, call `useScopedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useScopedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScopedUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      email: // value for 'email'
 *      filterGroup: // value for 'filterGroup'
 *      search: // value for 'search'
 *      attributeValuesIds: // value for 'attributeValuesIds'
 *   },
 * });
 */
export function useScopedUsersQuery(baseOptions: Apollo.QueryHookOptions<ScopedUsersQuery, ScopedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScopedUsersQuery, ScopedUsersQueryVariables>(ScopedUsersDocument, options);
      }
export function useScopedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScopedUsersQuery, ScopedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScopedUsersQuery, ScopedUsersQueryVariables>(ScopedUsersDocument, options);
        }
export type ScopedUsersQueryHookResult = ReturnType<typeof useScopedUsersQuery>;
export type ScopedUsersLazyQueryHookResult = ReturnType<typeof useScopedUsersLazyQuery>;
export type ScopedUsersQueryResult = Apollo.QueryResult<ScopedUsersQuery, ScopedUsersQueryVariables>;