import styled, { css } from "styled-components/macro"

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${props => props.theme.mixins.reducedLandscapeAndPortrait} {
    width: 100%;
  }
`

export const ErrorMessage = styled.p`
  margin: 40px 0;
  font-size: ${props => props.theme.typography.fontSizeTextSmall};
  color: ${props => props.theme.colors.danger};
`

export const Fields = styled.div`
  display: grid;
  row-gap: 16px;
  width: 100%;
  margin-bottom: 1rem;
`

export const Loader = styled.div<LoaderProps>`
  ${({ $isLoading }) => css`
    margin: 40px 0;
    
    ${$isLoading && css`
      background-color: #25A52A;
      display: none;
    `}
  `}
`

export interface LoaderProps {
  $isLoading: boolean,
}

