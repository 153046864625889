import * as React from "react"
import { RouteComponentProps } from "react-router"
import { useHistory } from "react-router-dom"
import { t } from "ttag"
import { useWindowSize } from "@humanpredictiveintelligence/myqvt-library"
import { ApolloError } from "@apollo/client/errors"

import { AuthenticationContext } from "features/Authentication"
import { useSession } from "features/Session"
import { APPLICATION_URL, ApplicationPath } from "features/Navigation"
import { ContentWithSpeakupLogo } from "features/Shared/ContentWithSpeakupLogo"
import { ChangePasswordForm } from "../ChangePasswordForm"
import * as Styles from "./ChangePasswordPage.styles"

import { useChangePasswordMutation } from "graphql/mutations/generated/ChangePassword"

export const ChangePasswordPage = (props: RouteComponentProps) => {
  const history = useHistory()
  const session = useSession()
  const windowSize = useWindowSize()
  const authentication = React.useContext(AuthenticationContext)

  const [ isPasswordChanged, setIsPasswordChanged ] = React.useState(false)
  const [ changePasswordErrorTimestamp, setChangePasswordErrorTimestamp ] = React.useState(0)
  const [ changePasswordError, setChangePasswordError ] = React.useState<string | undefined>(undefined)

  const [ changePassword, { loading: isPasswordBeingChanged } ] = useChangePasswordMutation()

  if (!session.authentication.isAuthenticated && !isPasswordChanged) {
    history.push(APPLICATION_URL.error(), {
      message: t`This link is expired. Please make a new reset password request.`,
      redirect: ApplicationPath.Login,
      redirectLabel: t`Sign in`,
    })
  }

  return (
    <ContentWithSpeakupLogo>
      <Styles.CardWrapper>
        <Styles.Title>{t`New password`}</Styles.Title>
        {isPasswordChanged && (
          <>
            <Styles.Instructions>
              {t`Your password has been successfuly modified. Please click bellow to proceed to the login interface.`}
            </Styles.Instructions>
            <Styles.Action
              onClick={gotoLogin}
            >
              {t`Sign in`}
            </Styles.Action>
          </>
        )}
        {!isPasswordChanged && (
          <ChangePasswordForm
            onSubmit={onSubmit}
            isLoading={isPasswordBeingChanged}
            formError={changePasswordError
              ? { message: changePasswordError, timestamp: changePasswordErrorTimestamp }
              : undefined
            }
            isTooltipDisabled={windowSize.isPortrait || windowSize.isReducedLandscape}
          />
        )}
      </Styles.CardWrapper>
    </ContentWithSpeakupLogo>
  )

  async function onSubmit(password: string) {
    try {
      await changePassword({
        variables: { password },
      })

      setIsPasswordChanged(true)
    }
    catch (error) {
      if (error instanceof ApolloError) {
        if (error.graphQLErrors.length) {
          setChangePasswordError(error.graphQLErrors[0].message)
        }
        else {
          setChangePasswordError("error")
        }
        setChangePasswordErrorTimestamp(Date.now())
      }
    }
  }

  function gotoLogin() {
    authentication.logout()
    props.history.push(ApplicationPath.Login)
  }
}
