import styled from "styled-components/macro"
import { InformationMessage, PrimaryButton, SecondaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const Title = styled.h1`
  font-size: ${props => props.theme.typography.fontSizeText};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  color: ${props => props.theme.colors.black};
  margin-bottom: 20px;
`

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  height: 100%;
`

export const PasswordHint = styled.div`
  margin-top: 16px;
  transition: font-weight 0.1s;
    
    &:hover {
        font-weight: bold;
    }
`

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
`

export const SubmitButton = styled(PrimaryButton)`
  margin-bottom: 24px;
`

export const StatusContainer = styled.div`
  height: 114px; /** The size of the loader */
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InformationMessageBox = styled(InformationMessage)`
  border-radius: 1.6rem;
  padding: 1.2rem;
`

export const NavigationActions = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`

export const SecondaryButtonAction = styled(SecondaryButton)`
  width: 100%;
`

export const PrimaryButtonAction = styled(PrimaryButton)`
  width: 100%;
`
