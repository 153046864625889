/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetupBackupEmailMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  actionToken: Types.Scalars['String'];
}>;


export type SetupBackupEmailMutation = { setupBackupEmail: boolean };


export const SetupBackupEmailDocument = gql`
    mutation SetupBackupEmail($email: String!, $actionToken: String!) {
  setupBackupEmail(email: $email, actionToken: $actionToken)
}
    `;

/**
 * __useSetupBackupEmailMutation__
 *
 * To run a mutation, you first call `useSetupBackupEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupBackupEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupBackupEmailMutation, { data, loading, error }] = useSetupBackupEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      actionToken: // value for 'actionToken'
 *   },
 * });
 */
export function useSetupBackupEmailMutation(baseOptions?: Apollo.MutationHookOptions<SetupBackupEmailMutation, SetupBackupEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupBackupEmailMutation, SetupBackupEmailMutationVariables>(SetupBackupEmailDocument, options);
      }
export type SetupBackupEmailMutationHookResult = ReturnType<typeof useSetupBackupEmailMutation>;
export type SetupBackupEmailMutationResult = Apollo.MutationResult<SetupBackupEmailMutation>;
export type SetupBackupEmailMutationOptions = Apollo.BaseMutationOptions<SetupBackupEmailMutation, SetupBackupEmailMutationVariables>;