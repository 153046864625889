import styled from "styled-components/macro"

export const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  text-align: center;
  
  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    animation: padding 0.25s;
  }
`
