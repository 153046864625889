import * as React from "react"
import { t } from "ttag"

import { CenteredLoader, Input, InputProps, PrimaryButton, useForm } from "@humanpredictiveintelligence/myqvt-library"
import { PasswordSecurityRules } from "../PasswordSecurityRules"
import business from "config/business"
import * as styles from "./ChangePasswordForm.styles"

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = props => {
  const [ isErrorMessageDisplayed, setErrorMessageDisplayed ] = React.useState(false)
  const form = useForm<ChangePasswordFormFields>({ mode: "onChange" })
  const reset = form.reset

  React.useEffect(() => {
    if (props.formError) {
      setErrorMessageDisplayed(true)
      reset()
    }
  }, [ reset, props.formError ])

  // When a field changes value, we hide the form error
  const allFieldsWatch = form.watch()
  React.useEffect(() => {
    if (form.isDirty && isErrorMessageDisplayed) {
      setErrorMessageDisplayed(false)
    }
  }, [ allFieldsWatch, form, isErrorMessageDisplayed ])

  return (
    <styles.Form onSubmit={form.handleSubmit(submit)}>
      <styles.Fields>
        <Input
          name="password"
          innerRef={form.register({
            pattern: {
              message: t`The password doesn't follow the security rules`,
              value: business.patterns.password,
            },
            required: t`This field is required`,
          })}
          label={t`Password`}
          isDisabled={props.isLoading}
          placeholder={t`Enter your password`}
          isErroneous={!!form.errors.password}
          isPassword
          {...hintAndTooltipPropsForPassword()}
          isHintErroneous
        />

        <Input
          name="passwordConfirm"
          innerRef={form.register({
            required: t`This field is required`,
            validate: value => {
              return value === form.getValues().password || t`The confirmation doesn't match the password`
            },
          })}
          label={t`Password confirmation`}
          isDisabled={props.isLoading}
          placeholder={t`Enter your password`}
          isErroneous={!!form.errors.passwordConfirm}
          isPassword
          hint={form.errors.passwordConfirm?.message}
          isHintErroneous
        />
      </styles.Fields>
      {!props.isLoading && props.formError && isErrorMessageDisplayed && (
        <styles.ErrorMessage>{props.formError.message}</styles.ErrorMessage>
      )}
      <div className="ChangePasswordForm__action">
        {props.isLoading
          ? <CenteredLoader isTransparent size="s" />
          : <PrimaryButton
            submit
            disabled={!form.isValid}
          >
            {t`Validate`}
          </PrimaryButton>
        }
      </div>
    </styles.Form>
  )

  /**
   * The action called from the form submission
   * @param values the values entered in the form
   */
  function submit(values: ChangePasswordFormFields) {
    props.onSubmit(values.password)
  }

  /**
   * Get the props managing the tooltip and hint text specifically for the password field
   * depending on whether there are errors in the form or we're on mobile
   */
  function hintAndTooltipPropsForPassword() {
    const helperTextProps:
      Pick<InputProps, "hint" | "tooltipText" | "tooltipDisplay" | "hintDisplay" | "isHintErroneous"> = {}

    if (props.isTooltipDisabled) {
      helperTextProps.hint = <PasswordSecurityRules />
      helperTextProps.isHintErroneous = form.errors.password !== undefined
      helperTextProps.tooltipText = undefined
      helperTextProps.hintDisplay = form.errors.password !== undefined ? "always" : "onfocus"
    }
    else {
      helperTextProps.tooltipText = <PasswordSecurityRules />
      helperTextProps.hint = form.errors.password && form.errors.password.message
      helperTextProps.isHintErroneous = form.errors.password !== undefined
      helperTextProps.hintDisplay = form.errors.password !== undefined ? "always" : "onfocus"
      helperTextProps.tooltipDisplay = form.errors.password !== undefined ? "always" : "onfocus"
    }

    return helperTextProps
  }
}

interface ChangePasswordFormProps {
  /** The method to call on submit action */
  onSubmit: (password: string) => void,

  /** Whether the tooltip should be displayed or not */
  isTooltipDisabled?: boolean,

  /** Whether to show the loading indicator */
  isLoading?: boolean,

  /**
   * Error message to display in the form
   * The timestamp is used to allow a message to be displayed again
  */
  formError?: {message: string, timestamp: number},
}

export type ChangePasswordFormFields = {
  password: string,
  passwordConfirm: string,
}
