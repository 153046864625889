import React from "react"
import { t } from "ttag"

import * as Styles from "./BackupEmail.styles"

export const BackupEmailWarning: React.FC<BackupEmailWarningProps> = (props) => {
  return (
    <Styles.Container>
      <div>
        <Styles.Title>{t`Backup email sent`}</Styles.Title>
        <Styles.InstructionParagraph>
          {t`A confirmation email has been sent to the address`} {props.backupEmail}.
        </Styles.InstructionParagraph>
        <Styles.InstructionParagraph>
          {t` Please check your mailbox and click on the confirmation link to validate your email address.`}
        </Styles.InstructionParagraph>
        <Styles.InformationMessageBox text={t`
          The code sent by email will be valid for 30 days only. After this period, you will no longer be able to 
          access the application without validating your code. 
        `}
        />
      </div>

      <div>
        <Styles.SubmitButton
          isLoading={props.formStatus === "processing"}
          onClick={props.onSkip}
        >
          {t`Sign in`}
        </Styles.SubmitButton>
      </div>
    </Styles.Container>
  )
}

interface BackupEmailWarningProps {
  backupEmail: string,
  onSkip: (isLoading?: boolean) => void,
  formStatus?: string,
}
