/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyUserFragmentFragmentDoc } from '../../../models/generated/SkinnyUserFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
  firstname?: Types.InputMaybe<Types.Scalars['String']>;
  lastname?: Types.InputMaybe<Types.Scalars['String']>;
  attributesIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  language?: Types.InputMaybe<Types.Scalars['String']>;
  timezone?: Types.InputMaybe<Types.Scalars['String']>;
  email?: Types.InputMaybe<Types.Scalars['String']>;
  phoneNumber?: Types.InputMaybe<Types.Scalars['String']>;
  scopes?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  permissions?: Types.InputMaybe<Array<Types.UserPermissionInput> | Types.UserPermissionInput>;
}>;


export type UpdateUserMutation = { me: { id: number, email?: string | undefined, backupEmail?: string | undefined, backupEmailValidateUntil?: string | undefined, timezone: string, firstname: string, lastname: string, displayName: string, phone?: string | undefined, isAdmin: boolean, language: { code: string, localizedLabel: string, translatedLabel: string }, attributes: Array<{ name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> }> } };


export const UpdateUserDocument = gql`
    mutation UpdateUser($userId: Int!, $firstname: String, $lastname: String, $attributesIds: [Int!], $language: String, $timezone: String, $email: String, $phoneNumber: String, $scopes: [Int!], $permissions: [UserPermissionInput!]) {
  me(id: $userId, firstName: $firstname, lastName: $lastname, email: $email, language: $language, timezone: $timezone, phoneNumber: $phoneNumber, attributeIds: $attributesIds, scopes: $scopes, permissions: $permissions) {
    ...SkinnyUserFragment
  }
}
    ${SkinnyUserFragmentFragmentDoc}`;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      attributesIds: // value for 'attributesIds'
 *      language: // value for 'language'
 *      timezone: // value for 'timezone'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      scopes: // value for 'scopes'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;