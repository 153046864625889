import { ApolloError } from "@apollo/client/errors"
import * as React from "react"
import { t } from "ttag"

import { AuthenticationContext } from "features/Authentication"
import { APPLICATION_URL } from "features/Navigation"
import { NotificationContext } from "features/Notification"
import { useSession } from "features/Session"
import { PatchNotesList } from "features/PatchNotes"
import {
  CenteredLoader,
  Icon,
  Input,
  PrimaryButton,
  SelectOptionProps,
} from "@humanpredictiveintelligence/myqvt-library"
import { STATIC_SESSION } from "utilities/StaticSession"
import business from "config/business"
import * as Styles from "./UserSettingsPage.styles"

import { useSetUserSettingsMutation } from "graphql/mutations/generated/SetUserSettings"
import { useUpdateMeMutation } from "graphql/mutations/generated/UpdateMe"
import { useLogoutLazyQuery } from "graphql/queries/generated/Logout"
import { useUserSettingsQuery } from "graphql/queries/generated/UserSettings"
import { useGetPatchnoteListQuery } from "graphql/queries/generated/GetPatchnoteList"

export const UserSettingsPage = () => {
  const authentication = React.useContext(AuthenticationContext)
  const notification = React.useContext(NotificationContext)
  const session = useSession()

  const [ selectedLanguageValue, setSelectedLanguageValue ] = React.useState<number>(0)
  const [ selectedCommentLanguages, setSelectedCommentLanguages ] = React.useState<string[] | undefined>()
  const [ isPatchNotesListOpen, setIsPatchNotesListOpen ] = React.useState(false)

  const [ backupEmail, setBackupEmail ] = React.useState<string>()
  const [ isEditingBackupEmail, setIsEditingBackupEmail ] = React.useState<boolean>(false)

  const { loading, data, error } = useUserSettingsQuery()

  const [ logout ] = useLogoutLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: () => {
      authentication.logout()
      window.location.reload()
    },
    onError: logoutError => {
      notification.show(t`Failure`, logoutError.graphQLErrors?.[0].message, "danger")
    },
  })

  const { data: patchnotesData, loading: arePatchNotesLoading } = useGetPatchnoteListQuery({
    variables: {
      limit: 0,
    },
  })

  const [ updateMe, { loading: userUpdatesAreLoading } ] = useUpdateMeMutation()
  const [ setUserSettings ] = useSetUserSettingsMutation()

  const languages = (data && data.languages && data.languages) || []
  const userLanguage = data && data.me && data.me.language && data.me.language.code
  const userCommentLanguages = data && data.me && data.me.settings.comment_languages

  React.useEffect(() => {
    setBackupEmail(data?.me.backupEmail ?? "")

    if (!selectedCommentLanguages && userCommentLanguages) {
      setSelectedCommentLanguages(userCommentLanguages.map(language => language.code))
    }
  }, [ data?.me.backupEmail, selectedCommentLanguages, userCommentLanguages ])

  if (error) {
    notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")

    authentication.logout()
    window.location.reload()
  }

  const isBackupEmailValid = React.useMemo(() => validateBackupEmail(backupEmail), [ backupEmail ])

  return (
    <Styles.Container>
      {loading && <CenteredLoader isTransparent/>}
      {!loading && data && (
        <>
          <div>
            <Styles.HeaderTitle level="page">
              {t`My Account`}
            </Styles.HeaderTitle>
            <Styles.UserName level="page">
              {`${data.me.firstName} ${data.me.lastName}`}
              <Styles.LogoutButton
                onClick={logout}
                icon="power_settings_new"
                isInverted
                size="big"
                aria-label={t`Logout button`}
              />
            </Styles.UserName>
          </div>
          <Styles.Section>
            <Styles.SectionTitle level="section">
              {t`Main email address`}
            </Styles.SectionTitle>
            <Styles.Email><Icon name="person" className="EmailIcon"/>{data.me.email}</Styles.Email>
            <p>
              {t`If you wish to change this address, contact us at `}
              <Styles.EmailLink href={`mailto:${STATIC_SESSION.contactEmail}`}>
                {STATIC_SESSION.contactEmail}
              </Styles.EmailLink>
            </p>

            {data.me.backupEmail && (
              <>
                <Styles.SectionTitle level="section">
                  {t`Backup email address`}
                </Styles.SectionTitle>
                <Styles.Email>
                  <Icon name="person" className="EmailIcon"/>
                  <Input
                    name="backupEmail"
                    isDisabled={!isEditingBackupEmail}
                    value={backupEmail}
                    onChange={setBackupEmail}
                  />

                  {!isEditingBackupEmail
                    ? (
                      <Styles.EditOrValidateBackupEmailButton
                        onClick={() => setIsEditingBackupEmail(true)}
                        icon="edit"
                        isInverted
                        size="standard"
                        aria-label={t`Edit backup email button`}
                      />
                    )
                    : (
                      <>
                        {userUpdatesAreLoading && <CenteredLoader size="s" isTransparent/>}
                        <Styles.EditOrValidateBackupEmailButton
                          onClick={saveUpdatedBackupEmail}
                          icon="done"
                          isInverted
                          size="standard"
                          aria-label={t`Validate backup email button`}
                          isDisabled={userUpdatesAreLoading || !isBackupEmailValid}
                        />
                      </>
                    )
                  }
                </Styles.Email>
              </>
            )}
          </Styles.Section>
          <Styles.Section>
            <Styles.SectionTitle level="section">
              {t`Password`}
            </Styles.SectionTitle>
            <Styles.Link to={APPLICATION_URL.changePasswordRequest()}>
              {t`Reset password`}
            </Styles.Link>
            <Styles.Information isSmall text={t`Important : a reset request will log you out.`}/>
          </Styles.Section>
          {(languages || userCommentLanguages) && (
            <Styles.Section>
              <Styles.SectionTitle level="section">
                {t`Language preferences`}
              </Styles.SectionTitle>
              {languages && (
                <>
                  <Styles.Label>{t`Interface Language`}</Styles.Label>
                  <Styles.LanguagesSelect
                    width="225px"
                    options={languages.map(
                      (language, i) => ({ value: i + 1, wording: language.localizedLabel }),
                    )}
                    value={
                      selectedLanguageValue
                      || languages.findIndex((language) => language.code === userLanguage) + 1
                    }
                    onChange={updateSelectedLanguage}
                    defaultItem={false}
                  />
                  <PrimaryButton
                    onClick={saveUserLanguage}
                    disabled={selectedLanguageValue === languages.findIndex(
                      (language) => language.code === userLanguage,
                    ) + 1}
                  >
                    {t`apply`}
                  </PrimaryButton>
                  <Styles.Information isSmall text={t`Important : changing the language will reload the page.`}/>
                </>
              )}
              {userCommentLanguages && (
                <>
                  <Styles.CommentLanguageLabel>
                    {t`Secondary languages for feedbacks`}
                  </Styles.CommentLanguageLabel>
                  <Styles.LanguagesSelect
                    isMultiselect
                    width="225px"
                    options={languages.reduce((options: SelectOptionProps[], language) => {
                      if (language.code !== session.customer!.language.code) {
                        options.push({ value: language.code, wording: language.translatedLabel })
                      }

                      return options
                    }, [])}
                    value={selectedCommentLanguages}
                    onMultiChange={updateSelectedCommentsLanguage}
                    placeholder={t`None`}
                    defaultItem={false}
                  />
                  <PrimaryButton
                    onClick={saveCommentLanguages}
                    disabled={
                      !selectedCommentLanguages
                      || (
                        userCommentLanguages.length === selectedCommentLanguages.length
                        && userCommentLanguages.every(
                          savedLanguage => selectedCommentLanguages.includes(savedLanguage.code),
                        )
                      )
                    }
                  >
                    {t`apply`}
                  </PrimaryButton>
                  <Styles.Information isSmall text={t`Important : adding or removing languages will reload the page.`}/>
                </>
              )}
            </Styles.Section>
          )}
          <Styles.Section>
            <Styles.SectionTitle level="section">
              {t`Support`}
            </Styles.SectionTitle>
            <p>
              {t`Need help ? Contact us at `}
              <Styles.EmailLink href={`mailto:${STATIC_SESSION.contactEmail}`}>
                {STATIC_SESSION.contactEmail}
              </Styles.EmailLink>
            </p>

            <Styles.PatchnotesLink
              onClick={() => setIsPatchNotesListOpen(true)}
            >
              {t`Update Notes`}
            </Styles.PatchnotesLink>
            <PatchNotesList
              isLoading={arePatchNotesLoading}
              isOpen={isPatchNotesListOpen}
              onClose={() => setIsPatchNotesListOpen(false)}
              patchNotes={patchnotesData?.getPatchnoteList}
            />
          </Styles.Section>
        </>
      )}
    </Styles.Container>
  )

  /**
   *  Update the selected UI language
   */
  function updateSelectedLanguage(language?: SelectOptionProps | undefined) {
    if (language) {
      setSelectedLanguageValue(language.value as number)
    }
  }

  /**
   *  Update the selected comments language
   */
  function updateSelectedCommentsLanguage(selectedLanguages: SelectOptionProps[]) {
    setSelectedCommentLanguages(
      selectedLanguages.map(commentsLanguage => commentsLanguage.value as string),
    )
  }

  async function saveUpdatedBackupEmail() {
    try {
      await updateMe({
        variables: {
          backupEmail: backupEmail,
        },
      })

      setIsEditingBackupEmail(false)
    }
    catch (e) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        return notification.show(t`Failure`, error.graphQLErrors[0].message, "danger")
      }
    }
  }

  /**
   * Save currently selected main language
   */
  async function saveUserLanguage() {
    if (data && selectedLanguageValue) {
      const language = data.languages[selectedLanguageValue - 1]
      try {
        await updateMe({
          variables: {
            userLanguage: language.code,
          },
        })

        window.location.reload()
      }
      catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
          return notification.show(t`Failure`, error.graphQLErrors[0].message, "danger")
        }
      }
    }
  }

  /**
   * Save selected comments languages
   */
  async function saveCommentLanguages() {
    if (selectedCommentLanguages) {
      try {
        await setUserSettings({
          variables: {
            settings: [
              { key: "comment_languages", value: selectedCommentLanguages.join(",") },
            ],
          },
        })

        window.location.reload()
      }
      catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
          return notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
        }
      }
    }
  }

  function validateBackupEmail(email?: string): boolean {
    if (!email) {
      return false
    }

    return (new RegExp(business.patterns.email)).test(email)
  }
}
