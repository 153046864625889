import styled from "styled-components"
import { InformationMessage, PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Title = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSizeTitleDialog};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  font-family: ${({ theme }) => theme.typography.fontSizeText};
  text-transform: uppercase;
`

export const InformationMessageBox = styled(InformationMessage)`
  border-radius: 1.6rem;
  padding: 1.2rem;
  margin-bottom: 1rem;
  overflow: hidden;
  white-space: break-spaces;
`

export const InstructionParagraph = styled.p`
  font-size: 1rem;
`

export const SubmitButton = styled(PrimaryButton)`
  margin-top: 1rem;
  width: 100%;
`

export const ForgotPasswordText = styled.p`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  cursor: pointer;
  text-decoration: underline;
`
