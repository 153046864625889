/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyUserFragmentFragmentDoc } from '../../../models/generated/SkinnyUserFragment';
import { SkinnyUserAttributeValueFragmentFragmentDoc } from '../../../models/generated/SkinnyUserAttributeValueFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyAssigneesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  email?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type MyAssigneesQuery = { me: { id: number, timezone: string, users: { assigneesCount: number, list: Array<{ id: number, email?: string | undefined, backupEmail?: string | undefined, backupEmailValidateUntil?: string | undefined, timezone: string, firstname: string, lastname: string, displayName: string, phone?: string | undefined, isAdmin: boolean, scopes: Array<{ id: number, name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { name: string, value: string, attributeId: number, valueId: number, isMainHierarchyHead: boolean, parent?: { name: string, value: string, attributeId: number, valueId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> } | undefined, children: Array<{ attributeId: number, valueId: number }> } | undefined, children: Array<{ attributeId: number, valueId: number }> }>, language: { code: string, localizedLabel: string, translatedLabel: string }, attributes: Array<{ name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> }> }> } } };


export const MyAssigneesDocument = gql`
    query MyAssignees($limit: Int!, $skip: Int, $email: String) {
  me {
    id
    timezone
    users(limit: $limit, skip: $skip, email: $email) {
      list {
        ...SkinnyUserFragment
        scopes {
          ...SkinnyUserAttributeValueFragment
          parent {
            ...SkinnyUserAttributeValueFragment
            parent {
              ...SkinnyUserAttributeValueFragment
            }
          }
          id
        }
      }
      assigneesCount: count
    }
  }
}
    ${SkinnyUserFragmentFragmentDoc}
${SkinnyUserAttributeValueFragmentFragmentDoc}`;

/**
 * __useMyAssigneesQuery__
 *
 * To run a query within a React component, call `useMyAssigneesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAssigneesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAssigneesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useMyAssigneesQuery(baseOptions: Apollo.QueryHookOptions<MyAssigneesQuery, MyAssigneesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAssigneesQuery, MyAssigneesQueryVariables>(MyAssigneesDocument, options);
      }
export function useMyAssigneesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAssigneesQuery, MyAssigneesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAssigneesQuery, MyAssigneesQueryVariables>(MyAssigneesDocument, options);
        }
export type MyAssigneesQueryHookResult = ReturnType<typeof useMyAssigneesQuery>;
export type MyAssigneesLazyQueryHookResult = ReturnType<typeof useMyAssigneesLazyQuery>;
export type MyAssigneesQueryResult = Apollo.QueryResult<MyAssigneesQuery, MyAssigneesQueryVariables>;