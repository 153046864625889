export enum LoginStep {
  Login,
  Mfa,
  ChangeBackupEmail,
  ConfirmBackupEmail,
  ExpiredBackupEmail,
}

export interface UserState {
  email: string,
  backupEmail?: string,
  password: string,
  authStepToken?: string,
  setupEmailBackupActionToken?: string,
  isBackupEmailValidationExpired?: boolean,
  isBackupEmailMissing?: boolean,
  skipBackupEmailValidation?: boolean,
  errorMessage?: string,
}

