import React from "react"
import useForm from "react-hook-form"
import { t } from "ttag"
import { Input } from "@humanpredictiveintelligence/myqvt-library"

import { useSession } from "features/Session"
import { FormStatus } from "utilities/types"
import business from "config/business"
import * as LoginStyles from "features/Login/LoginForm/LoginForm.styles"
import * as Styles from "./BackupEmail.styles"

import { useSetupBackupEmailMutation } from "graphql/mutations/generated/SetupBackupEmail"

export const ChangeBackupEmailForm: React.FC<ChangeBackupEmailFormProps> = (props) => {
  const session = useSession()
  const [ formStatus, setFormStatus ] = React.useState<FormStatus>("idle")
  const [ updateBackupEmail, { loading: isLoading } ] = useSetupBackupEmailMutation()

  const {
    register: registerField,
    handleSubmit,
    errors: formErrors,
    watch,
  } = useForm<FormFields>({
    mode: "onChange",
  })

  const email = watch("email")
  const confirmEmail = watch("emailConfirmation")

  const isEmailsMatching = email && confirmEmail && email === confirmEmail

  return (
    <Styles.Container>
      <div>
        <Styles.Title>{t`Backup email configuration`}</Styles.Title>
        <Styles.InformationMessageBox text={
          t`In addition to your password, MFA asks you to confirm your identity by another means,
          such as a code sent by e-mail.`
          +
          t`This address will only be used if you lose access to your main email address,
          to ensure that you can always access your account securely.`
        }/>
      </div>
      <Styles.Form onSubmit={handleSubmit(onSubmit)}>
        <LoginStyles.Fields>
          <Input
            name="email"
            innerRef={registerField({
              pattern: {
                message: t`The email format is invalid`,
                value: business.patterns.email,
              },
              required: t`This field is required`,
            })}
            label={t`Backup email`}
            isDisabled={formStatus === "processing"}
            placeholder="exemple@adresse.com"
            isErroneous={!!formErrors.email}
            hint={formErrors.email && formErrors.email.message}
            isHintErroneous
          />
          <Input
            name="emailConfirmation"
            innerRef={registerField({
              pattern: {
                message: t`The email format is invalid`,
                value: business.patterns.email,
              },
              required: t`This field is required`,
              validate: (confirmEmail) => {
                return watch("email") === confirmEmail
              },
            })}
            label={t`Backup email confirmation`}
            isDisabled={formStatus === "processing"}
            placeholder="exemple@adresse.com"
            isErroneous={!!formErrors.email}
            hint={formErrors.email && formErrors.email.message}
            isHintErroneous
          />
        </LoginStyles.Fields>

        {isEmailsMatching && (
          formStatus === "failed"
            ? (
              <LoginStyles.ErrorMessage>
                {session.authentication.reason ?? t`An error occurred while updating your email.`}
              </LoginStyles.ErrorMessage>
            )
            : (
              <Styles.InformationMessageBox
                text={t`
                  You will need to enter another MFA code which you will receive after setting your backup email.
                `}
              />
            )
        )}

        <Styles.SubmitButton
          submit
          disabled={!isEmailsMatching}
          isLoading={isLoading || props.formStatus === "processing"}
        >
          {props.mode === "change" ? t`Change my backup email` : t`Set up my backup email`}
        </Styles.SubmitButton>

      </Styles.Form>
    </Styles.Container>
  )

  async function onSubmit(form: FormFields) {
    setFormStatus("processing")
    const updateBackupEmailStatus = await updateBackupEmail({
      variables: {
        actionToken: props.actionToken,
        email: form.emailConfirmation,
      },
    })

    if (updateBackupEmailStatus.data?.setupBackupEmail) {
      props.onBackupEmailChanged(form.emailConfirmation)
    }
    else {
      setFormStatus("failed")
    }
  }
}

interface FormFields {
  email: string,
  emailConfirmation: string,
}

interface ChangeBackupEmailFormProps {
  actionToken: string,
  onBackupEmailChanged: (newEmail: string) => void,
  mode: "setup" | "change",
  formStatus?: string,
}
