import moment from "moment"
import * as React from "react"
import { t } from "ttag"
import { ApolloError } from "@apollo/client/errors"

import {
  ScheduledSurveyFeedbackState,
  multiLanguageFeedbackReducer,
} from "features/Surveys/ScheduledSurveyResults/MultiLanguageFeedback/MultiLanguageFeedbackReducer"
import { NotificationContext } from "features/Notification"
import { useSession } from "features/Session"
import { useUncommittedChanges } from "features/UncommitedChanges"
import {
  BaseProps,
  PrimaryButton,
  SearchableSelect,
  SearchableSelectOption,
} from "@humanpredictiveintelligence/myqvt-library"
import { Comment as CommentModel } from "models/Comment"
import { COMMENT_TRANSLATIONS_SERVICE } from "services/CommentTranslationsService"
import * as Types from "models/generated"
import { CommentObjectType, Comment_Translation as CommentTranslation } from "models/generated"

import * as Styles from "features/Surveys/ScheduledSurveyResults/MultiLanguageFeedback/MultiLanguageFeedback.styles"
import { SkinnyResponseCommentFragmentFragment } from "models/generated/SkinnyResponseCommentFragment"

/**
 * Components to create a feedback in different languages.
 * e.g. It is used to create a general feedback for the company in all languages spoken by the company
 *
 * @param props
 * @constructor
 */
export const MultiLanguageFeedback: React.FC<MultiLanguageFeedbackProps> = (props) => {
  const notification = React.useContext(NotificationContext)
  const session = useSession()
  const user = session.user && session.user
  const isAllowedToWriteFeedback = props.isAllowedToWrite?.()
  const isAllowedToPublishFeedback = props.isAllowedToPublish?.()

  const {
    uncommittedChange: storedState,
    setUncommittedChange: storeState,
    removeUncommittedChange: removeStoredState,
  } = useUncommittedChanges<ScheduledSurveyFeedbackState>(`global_comment_${props.commentObjectType}_${props.parentId}`)

  const [ state, dispatch ] = React.useReducer(multiLanguageFeedbackReducer, storedState ?? initialState())
  const [ isSaving, setIsSaving ] = React.useState(false)
  const [ isDeleting, setIsDeleting ] = React.useState(false)

  React.useEffect(() => {
    if (!storedState) {
      dispatch({
        isEditing: !props.comment || !props.comment.validatedAt || props.areResultsAlreadyPublished || false,
        storedComment: props.comment,
        type: "ResetAllTranslations",
      })
    }
  }, [ props.comment, props.areResultsAlreadyPublished, storedState ])

  React.useEffect(() => {
    if (!state.isDirty) {
      removeStoredState()
    } else {
      storeState(state)
    }
  }, [ removeStoredState, state, storeState ])

  return (
    <Styles.Container className={props.className}>
      <Styles.Explanation
        text={t`Once published, your feedback will be visible in the results interface of all users.`}
      />

      <Styles.FeedbackInput
        key={state.translations.default.language.code}
        fieldClassNames="FeedBackInput__field"
        label={state.translations.default.language.translatedLabel + ` (${t`By default`})`}
        value={state.translations.default.wording}
        isDisabled={!state.isEditing || !state.translations.default.isEditable || !isAllowedToWriteFeedback}
        onChange={(newValue) => {
          dispatch({
            languageCode: state.translations.default.language.code,
            languageKind: "default",
            newValue,
            type: "UpdateTranslation",
          })
        }}
      />

      {state.translations.user.map(commentTranslation => (
        <Styles.FeedbackInput
          key={commentTranslation.language.code}
          fieldClassNames="FeedBackInput__field"
          label={commentTranslation.language.translatedLabel}
          value={commentTranslation.wording}
          isDisabled={!state.isEditing || !commentTranslation.isEditable || !isAllowedToWriteFeedback}
          onChange={(newValue) => dispatch({
            languageCode: commentTranslation.language.code,
            languageKind: "user",
            newValue,
            type: "UpdateTranslation",
          })}
        />
      ))}
      {state.translations.extra.map(commentTranslation => (
        <Styles.FeedbackInput
          key={commentTranslation.language.code}
          fieldClassNames="FeedBackInput__field"
          label={commentTranslation.language.translatedLabel}
          value={commentTranslation.wording}
          isDisabled={!state.isEditing || !commentTranslation.isEditable || !isAllowedToWriteFeedback}
          onChange={(newValue) => dispatch({
            languageCode: commentTranslation.language.code,
            languageKind: "extra",
            newValue,
            type: "UpdateTranslation",
          })}
        />
      ))}

      {statusText()}

      <Styles.Actions>
        {state.extraLanguagesSelectOptions.length > 0 && (
          <SearchableSelect
            options={state.extraLanguagesSelectOptions}
            defaultValues={state.selectedExtraLanguages.map(selectedLanguage => selectedLanguage.code)}
            label={t`Additional Languages`}
            selectPlaceholder={t`Select`}
            isMultiselect
            isDisabled={!state.isEditing}
            onChange={selection => dispatch({
              selection,
              type: "SelectExtraLanguages",
            })}
          />
        )}
        <Styles.CrudActions>
          {!(props.areResultsAlreadyPublished && state.storedComment && state.storedComment.validatedAt) && (
            <>
              <PrimaryButton
                isInverted
                disabled={
                  (!state.isEditing
                    || !state.storedComment
                    || state.translations.default.wording === "") && !state.isDirty
                }
                isAllowed={isAllowedToWriteFeedback}
                tooltip={!isAllowedToWriteFeedback
                  ? t`You don't have sufficient rights to perform this action`
                  : undefined
                }
                tooltipPlacement={"top"}
                onClick={() => saveTranslations(true)}
                isLoading={isSaving}
              >
                {t`Save as draft`}
              </PrimaryButton>
              {!props.areResultsAlreadyPublished && (
                <PrimaryButton
                  isInverted
                  disabled={state.isEditing}
                  isAllowed={isAllowedToWriteFeedback}
                  onClick={deleteTranslations}
                  tooltip={!isAllowedToWriteFeedback
                    ? t`You don't have sufficient rights to perform this action`
                    : undefined
                  }
                  tooltipPlacement={"top"}
                  isLoading={isDeleting}
                >
                  {t`Delete`}
                </PrimaryButton>
              )}
              {!props.areResultsAlreadyPublished && (
                <PrimaryButton
                  disabled={
                    (
                      !(state.storedComment && !state.storedComment.validatedAt)
                      && !state.isDirty
                    )
                    || state.translations.default.wording === ""
                  }
                  isAllowed={isAllowedToWriteFeedback}
                  tooltip={!isAllowedToWriteFeedback
                    ? t`You don't have sufficient rights to perform this action`
                    : undefined
                  }
                  tooltipPlacement={"top"}
                  onClick={() => saveTranslations(false)}
                  isLoading={isSaving}
                >
                  {t`Confirm`}
                </PrimaryButton>
              )}
              {props.areResultsAlreadyPublished && (
                <PrimaryButton
                  disabled={
                    (
                      !(state.storedComment && !state.storedComment.validatedAt)
                      && !state.isDirty
                    )
                    || state.translations.default.wording === ""
                  }
                  isAllowed={isAllowedToPublishFeedback}
                  tooltip={!isAllowedToPublishFeedback
                    ? t`You don't have sufficient rights to perform this action`
                    : undefined
                  }
                  tooltipPlacement={"top"}
                  onClick={() => saveTranslations(false)}
                  isLoading={isSaving}
                >
                  {t`Publish`}
                </PrimaryButton>
              )}
            </>
          )}
        </Styles.CrudActions>
      </Styles.Actions>
    </Styles.Container>
  )

  /**
   * Delete stored comment (on server and state) and switch to edit mode
   */
  async function deleteTranslations() {
    setIsDeleting(true)

    let comment
    if (state.storedComment) {
      // Empty the translation in the default language to delete the comment
      comment = await props.update?.(state.storedComment.id, false, [ {
        comment: "",
        language: session.customer!.language.code,
      } ])
    }

    dispatch({
      isEditing: false,
      storedComment: comment,
      type: "ResetAllTranslations",
    })
    dispatch({ type: "ToggleEditing" })

    await props.onSave?.()

    notification.show(
      t`Success`,
      t`Your comment was successfully deleted`,
      "success",
    )
    setIsDeleting(false)
  }

  /**
   * Save all translations
   */
  async function saveTranslations(isDraft: boolean) {
    setIsSaving(true)

    try {
      let comment
      if (state.storedComment) {
        comment = await props.update?.(state.storedComment.id, isDraft, allTranslationsForMutation())
      }
      else {
        comment = await props.add?.(props.parentId, isDraft, allTranslationsForMutation())
      }

      if (!comment) {
        return
      }

      dispatch({
        isEditing: isDraft ? true : !comment || !comment.validatedAt || props.areResultsAlreadyPublished || false,
        storedComment: comment,
        type: "ResetAllTranslations",
      })

      await props.onSave?.()

      notification.show(
        t`Success`,
        isDraft ? t`Your draft was successfully saved` : t`Your comment was successfully saved`,
        "success",
      )
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
      }
    }
    finally {
      setIsSaving(false)
    }
  }

  /**
   * Get the comment status to display
   */
  function statusText(): React.ReactNode {
    let publicationStatus: React.ReactNode

    if (props.areResultsAlreadyPublished && state.storedComment && state.storedComment.publishedAt) {
      const publishingDate = moment.parseZone(state.storedComment.publishedAt).format("L")

      publicationStatus = (
        <>
          {t`Published on ${publishingDate}`}
          {state.storedComment.creator && (
            <Styles.Author>
              {state.storedComment.creator && (
                t` by ${state.storedComment.creator.displayName}`
              )}
            </Styles.Author>
          )}
        </>
      )
    } else if (
      !props.areResultsAlreadyPublished
      && state.storedComment
      && state.storedComment.validatedAt
    ) {
      publicationStatus = (
        publicationStatus = t`Ready to be published`
      )
    } else if (state.storedComment && !state.storedComment.publishedAt) {
      if (state.isDirty) {
        publicationStatus = t`Unsaved`
      } else {
        publicationStatus = t`Draft saved`
      }
    }

    return publicationStatus ? (
      <Styles.Status>
        {publicationStatus}
      </Styles.Status>
    ) : null
  }

  /**
   * Get the complete initial state of the component's reducer
   */
  function initialState(): ScheduledSurveyFeedbackState {
    let extraLanguagesSelectOptions: SearchableSelectOption[]
    if (props.areResultsAlreadyPublished && props.comment && !!props.comment.validatedAt) {
      extraLanguagesSelectOptions = []
    } else {
      extraLanguagesSelectOptions = COMMENT_TRANSLATIONS_SERVICE.availableExtraLanguagesOptions(
        session.languages,
        session.customer!.language,
        user!.settings.comment_languages,
        props.comment,
      )
    }

    return {
      extraLanguagesSelectOptions,
      isEditing: !props.comment || !props.comment.validatedAt || props.areResultsAlreadyPublished,
      languages: {
        app: session.languages,
        customer: session.customer!.language,
        user: user!.settings.comment_languages,
      },
      selectedExtraLanguages: [],
      storedComment: props.comment ? Object.assign({}, props.comment) : undefined,
      translations: Object.assign(
        {},
        COMMENT_TRANSLATIONS_SERVICE.allTranslationsFromSource(
          props.comment,
          session.customer!.language,
          user!.settings.comment_languages,
        ),
      ),
    }
  }

  /**
   * Get an array of all the translations in the form expected by the comment mutations
   */
  function allTranslationsForMutation(): CommentTranslation[] {
    return [ state.translations.default, ...state.translations.user, ...state.translations.extra ]
      .filter(translation => translation.isDirty || translation.wording !== "")
      .map(translation => ({
        comment: translation.wording,
        language: translation.language.code,
      }))
  }
}

interface MultiLanguageFeedbackProps extends BaseProps {
  /** ID of the feedback question or scheduledSurvey */
  parentId: number,

  /** Whether this is a question feedback or a survey feedback */
  commentObjectType: CommentObjectType.QuestionScheduledSurvey | CommentObjectType.ScheduledSurvey,

  /** Comment shown */
  comment?: CommentModel,

  /** Date of publishing if the comment is already published */
  publishingDate?: string,

  /** Whether the results are already accessible to recipients */
  areResultsAlreadyPublished?: boolean,

  /** Optional callback when the comment is saved (created or updated) */
  onSave?: () => void,

  /** Optional method called once the comment is added to manage cache issues */
  onPublish?: () => void,

  /** Callback called to check if the user is allowed to publish the feedback */
  isAllowedToWrite?: () => boolean,

  /** Callback called to check if the user is allowed to publish the feedback */
  isAllowedToPublish?: () => boolean,

  /** Contains the logic to save a new feedback (mutation call, etc.) */
  add?: (
    id: number,
    isDraft: boolean,
    translations: CommentTranslation[]
  ) => Promise<Types.Maybe<SkinnyResponseCommentFragmentFragment>>,

  /** Contains the logic to save an existing feedback (mutaton call, etc.) */
  update?: (
    id: number,
    isDraft: boolean,
    translations: CommentTranslation[]
  ) => Promise<Types.Maybe<SkinnyResponseCommentFragmentFragment>>,
}
