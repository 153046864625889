import React from "react"
import useForm from "react-hook-form"
import { t } from "ttag"
import { CenteredLoader, Input } from "@humanpredictiveintelligence/myqvt-library"

import { useSession } from "features/Session"
import { ResendMfaCodeButton } from "../Components/ResendMfaCodeButton"
import * as Styles from "../LoginForm/LoginForm.styles"

export const MfaCodeForm: React.FC<MfaCodeFormProps> = (props) => {
  const session = useSession()
  const {
    register: registerField,
    handleSubmit,
    errors: formErrors,
  } = useForm<FormFields>({
    mode: "onChange",
  })

  return (
    <Styles.Form aria-label="form" onSubmit={handleSubmit(onSubmit)}>
      <Styles.Title>{t`Code validation`}</Styles.Title>
      <Styles.Fields>
        <Styles.InformationMessageBox text={t`A confirmation code has been sent to the address ${props.userEmail}`}/>
        <Input
          name="code"
          innerRef={registerField({
            required: t`This field is required`,
          })}
          label={t`Code`}
          isDisabled={props.formStatus === "processing"}
          placeholder={t`Enter the code`}
          isErroneous={!!formErrors.code}
          hint={formErrors.code && formErrors.code.message}
          isHintErroneous
        />
      </Styles.Fields>
      <Styles.StatusContainer>
        {props.formStatus === "failed" && (
          <Styles.ErrorMessage>
            {session.authentication.reason}
          </Styles.ErrorMessage>
        )}

        {props.formStatus === "processing" && <CenteredLoader isTransparent />}
      </Styles.StatusContainer>
      <ResendMfaCodeButton onRetry={props.onRetryRequested}/>
      <Styles.NavigationActions>
        <Styles.SecondaryButtonAction
          onClick={props.onBack}
        >
          {t`Back`}
        </Styles.SecondaryButtonAction>
        <Styles.PrimaryButtonAction
          submit
          disabled={props.formStatus === "processing"}
          style={{ backgroundColor: "white" }}
        >
          {t`Connection`}
        </Styles.PrimaryButtonAction>
      </Styles.NavigationActions>
    </Styles.Form>
  )

  function onSubmit(form: FormFields) {
    props.onCodeValidated(form.code)
  }
}

interface FormFields {
  code: string,
}

interface MfaCodeFormProps {
  userEmail: string,
  onCodeValidated: (code: string) => void,
  onRetryRequested: () => void,
  onBack: () => void,
  formStatus?: string,
}
